<template>
  <div :key="mode">
    <AppTable
      :columns="columns"
      :is-loading="requestInProgress"
      :pagination="{
        limit: users.limit,
        count: users.count,
        page: users.page,
      }"
      :rows="users.value"
      @change-limit="changePageLimit"
      @change-page="changePage"
    >
      <template #prependFilters>
        <b-row>

          <b-col
            class="mb-1"
            md="3"
            xl="3"
          >
            <b-form-group>
              <label class="mr-1">Telegram ID</label>
              <b-form-input
                v-model="telegramID"
                placeholder="Search"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col
            class="mb-1"
            md="3"
            xl="3"
          >
            <b-form-group>
              <label class="mr-1">Username</label>
              <b-form-input
                v-model="username"
                placeholder="Search"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col
            class="mb-1"
            md="3"
            xl="3"
          >
            <b-form-group>
              <label class="mr-1">Email</label>
              <b-form-input
                v-model="email"
                placeholder="Search"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col
            class="mb-1 mt-auto pb-1"
            md="1"
            xl="2"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="requestInProgress"
              class="mt-auto"
              type="button"
              variant="primary"
              @click="search"
            >
              <feather-icon
                icon="SearchIcon"
                size="18"
              />
            </b-button>
          </b-col>
        </b-row>
      </template>

      <template #default="{ column, row, formattedRow} = {}">
        <TableRow
          :column="column"
          :formatted-row="formattedRow"
          :row="row"
        />

        <span v-if="column.field === 'status'">
          <b-badge
            :variant="row.status ? 'success' : 'danger'"
            class="text-white"
            pill
          >
            {{ row.status ? 'Active' : 'Block' }}
          </b-badge>
        </span>

        <span v-else-if="column.field === 'kycStatus'">
          <b-badge
            :variant="row.kycStatus === 'success' ? 'success' : 'danger'"
            class="text-white"
          >
            {{ row.kycStatus }}
          </b-badge>
        </span>

        <span v-else-if="column.field === 'kycLevel'">
          <b-badge
            class="text-white"
            variant="primary"
          >
            {{ row.kycLevel }}
          </b-badge>
        </span>

        <span v-else-if="column.field === 'telegram_username'">
          <a
            :href="`https://t.me/${row.telegram_username}`"
            target="_blank"
          >
            {{ row.telegram_username }}
          </a>
        </span>

        <span v-else-if="column.field === 'isCeFiEnabled'">
          <b-form-checkbox
            :checked="row.isCeFiEnabled"
            class="custom-control-success"
            name="check-button"
            switch
            @change="handleCefiChange(row)"
          />
        </span>

        <span v-else-if="column.field === 'hasViewerRole'">
          <b-form-checkbox
            :checked="row.hasViewerRole"
            :disabled="!row.canHaveViewerRole"
            class="custom-control-success"
            name="check-button"
            switch
            @change="handleAdminViewChange(row)"
          />
        </span>

        <span v-else-if="column.field === 'withdrawalStatus'">
          <b-badge
            :variant="row.withdrawalStatus === 'recheck' ? 'warning' : 'danger'"
            class="text-white"
          >
            {{ row.withdrawalStatus }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span
          v-else-if="column.field === 'action'"
          class="d-flex"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="row.status ? 'danger' : 'success'"
            class="mt-auto text-white"
            type="button"
            @click="handleUserActivation(row)"
          >
            <span
              class="text-white"
              v-html="row.status ? 'Block&nbsp;User' : 'Activate&nbsp;user'"
            />
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-auto text-white ml-1"
            type="button"
            variant="info"
            @click="toUser(row)"
          >
            User&nbsp;view
          </b-button>
        </span>

        <!-- default render cell -->
        <span
          v-else
          class="flex-center-align"
        >
          {{ formattedRow[column.field] }}
        </span>
      </template>
    </AppTable>
  </div>
</template>

<script>
import {
  BBadge, BButton, BCol, BFormCheckbox, BFormGroup, BFormInput, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
import { columns, columnsCustom } from './config/tableConfig'
import TableRow from '@/components/TableRow.vue'
import AppTable from '@/components/AppTable.vue'

export default {
  components: {
    AppTable,
    TableRow,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      columnsCustom,
      columnsDefault: columns,
      telegramID: '',
      username: '',
      email: '',
    }
  },

  computed: {
    columns() {
      return this.mode === 'forbidden' ? this.columnsCustom : this.columnsDefault
    },
    ...mapGetters({
      users: 'users/users',
      listUsers: 'users/listUsers',
      totalUsers: 'users/totalUsers',
      pageUserPagination: 'users/pageUserPagination',
    }),

    mode() {
      const { name } = this.$route
      return name.includes('kyc') ? 'forbidden' : 'users'
    },

    direction() {
      return store.state.appConfig.isRTL
    },
  },
  watch: {
    mode() {
      this.telegramID = ''
      this.username = ''
      this.email = ''
      const query = this.createQueryConfig({ page: 1 })

      this.fetchUser(query)
    },
  },
  mounted() {
    this.initState()
  },
  methods: {
    ...mapActions({
      fetchListUsers: 'users/fetchListUsers',
      blockUser: 'users/blockUser',
      activateUser: 'users/activateUser',
      toggleCeFi: 'users/toggleCeFi',
      toggleAdminView: 'users/toggleAdminView',
    }),

    initState() {
      const limit = this.selectPerPage
      const query = this.createQueryConfig({ limit })

      this.fetchUser(query)
    },

    search() {
      const query = this.createQueryConfig({
        telegramId: this.telegramID,
        userName: this.username,
        email: this.email,
        page: 1,
      })

      return this.fetchUser(query)
    },

    changePageLimit(pageLimit) {
      const query = this.createQueryConfig({ limit: pageLimit })

      return this.fetchUser(query)
    },

    changePage(pageNumber) {
      const query = this.createQueryConfig({ page: pageNumber })

      return this.fetchUser(query)
    },

    createQueryConfig(config) {
      return {
        page: this.users.page,
        limit: this.users.limit,
        mode: this.mode,
        telegramID: this.telegramID || undefined,
        username: this.username || undefined,
        email: this.email || undefined,

        ...config,
      }
    },

    fetchUser(query) {
      return this.waitRequest(() => this.fetchListUsers(query).catch(this.checkErrorsAlert))
    },

    handleUserActivation(user) {
      const isActive = user.status
      const actionText = isActive ? 'block' : 'activate'

      this.$swal({
        title: `Do you want to ${actionText} user?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Yes, ${actionText} '${user?.email}'!`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (!result.isConfirmed) return

        if (isActive) return this.block(user)

        return this.activate(user)
      })
    },

    activate(user) {
      if (this.requestInProgress) return

      this.waitRequest(() => this.activateUser({ id: user.id })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'User activate',
              icon: 'BellIcon',
            },
          })
        })
        .catch(this.checkErrors))
    },

    block(user) {
      if (this.requestInProgress) return

      this.waitRequest(() => this.blockUser({ id: user.id })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'User blocked',
              icon: 'BellIcon',
            },
          })
        })
        .catch(this.checkErrors))
    },

    toUser(row) {
      this.$router.push(`/user/${row.id}`)
    },

    handleCefiChange(user) {
      if (this.requestInProgress) return

      this.waitRequest(() => this.toggleCeFi({ id: user.id }))
    },

    handleAdminViewChange(user) {
      if (this.requestInProgress) return

      this.waitRequest(() => this.toggleAdminView({ id: user.id }))
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-sweetalert.scss';
</style>
